<template>
  <v-dialog v-model="delete_bank_account_modal" width="550">
    <v-card class="pa-3">
      <v-card-title class="text-h5"> Delete Bank Account </v-card-title>
      <v-card-text
        >Are you sure you want to delete this bank account?</v-card-text
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="red"
          dark
          elevation="0"
          @click="passDelete($event)"
          :loading="loading_delete"
        >
          <v-icon left small>mdi-delete</v-icon>
          Delete
        </v-btn>
        <v-btn text @click="passCloseDialog"> Cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "BankAccountDeleteConfirmDialog",
  props: ["delete_bank_account_modal", "loading_delete"],
  data() {
    return {};
  },
  methods: {
    passDelete() {
      this.$emit("deleteAccount");
    },
    passCloseDialog() {
      this.$emit("closeDialog", false);
    },
  },
};
</script>
